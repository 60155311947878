<!-- 我的课程 -->
<template>
	<div class="main-container">
		<div class="side-header">
			<div class="name">
				录播课程
			</div>
		</div>
		<div class="main-content">
      <div class="course-list">
        <div class="course-item" v-for="(item,index) in list" :key="index">
          <div class="course-content">
            <img :src="item.thumb" class="course-img" alt="">
            <div class="course-info">
              <div class="name">{{item.title}}</div>
              <div class="intro">{{item.description}}</div>
              <div class="progress">
                <el-progress :show-text="false" :stroke-width="10" :percentage="item.learn"></el-progress>
                <div class="txt">已学习{{item.learn}}%</div>
              </div>
            </div>
          </div>
          <div class="course-btn">
            <el-button type="primary" :disabled="!item.isCan" @click="onRoute(item)">继续学习</el-button>
          </div>
        </div>
      </div>
      <el-pagination
        background
        :current-page="page"
        layout="prev, pager, next"
        :hide-on-single-page="true"
        @current-change="onChangePage"
        v-if="list.length"
        :page-count="totalPage">
      </el-pagination>
      <el-empty description="暂无数据" v-if="!list.length"></el-empty>
		</div>
	</div>
</template>

<script>
import { getToken } from '@/utils/auth';
export default {
  data() {
    return {
      page:1,
      list:[],
      totalPage:0,
    };
  },
  created(){
    this.getMemberCourseList();
  },
  methods: {
    getMemberCourseList(){
      this.$http
      .get('/api/web/course/getMemberCourseList?page='+this.page)
      .then((res) => {
        this.list=res.data.memberCourseList;
        this.totalPage=res.data.totalPage;
      })
    },
    onChangePage(val){
      this.page=val;
      this.getMemberCourseList()
    },
    onRoute(item){
      this.$router.push({
        name:'CoursePlay',
        query:{
          cId:item.courseChapterId,
          ipType:item.ipType,
        }
      })
    },
  },
};
</script>

<style lang='less' scoped>
.main-container {
  background: #fff;
  min-height: 750px;
  .side-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 68px;
    padding: 0 20px 0 40px;
    border-bottom: 1px solid #F5F5F5;
    .name {
      font-size: 16px;
      color: #333;
    }
    .operate {
      line-height: 68px;
      padding: 0 20px;
      color: #D71A18;
      cursor: pointer;
    }
  }
  .main-content {
    padding: 0 30px 20px;
    .course-list{
      padding: 10px 0;
      line-height: 1;
      .course-item{
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f5f5f5;
        &:last-child{
          border-bottom: none;
        }
        .course-content{
          display: flex;
          padding:40px 0 30px;
          .course-img{
            width: 160px;
            height: 90px;
          }
          .course-info{
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            margin-left: 30px;
            min-width: 600px;
            .name{
              margin-bottom: 30px;
              font-size: 18px;
              color: #333;
            }
            .intro{
              font-size: 16px;
              color: #999;
              margin-bottom: 15px;
            }
            .progress{
              width: 100%;
              display: flex;
              align-items: center;
              .el-progress{
                width: calc(100% - 100px);
                margin-right: 20px;
              }
              .txt{
                font-size: 14px;
                color: #666;
                &.active{
                  color: var(--main-active-color);
                }
              }
            }
          }
        }
        .course-btn{
          margin-left: 80px;
        }
      }
    }
  }
}
</style>
